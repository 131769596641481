.para-clip {
  clip-path: polygon(0 0, 100% 0%, 90% 100%, 0% 100%);
}

.para-clip-personal {
  clip-path: polygon(0 0, 100% 0%, 97% 100%, 3% 100%)
}

.para-clip-2 {
  clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
}

.para-clip-3 {
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0% 100%);
}

.para-clip-create {
  clip-path: polygon(0 0, 100% 0%, 91% 100%, 0% 100%);
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper {
  padding: 1rem 0px 4rem 0px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination-bullet {
  background-color: #ffffff !important;
  opacity: 1 !important;
  height: 16px !important;
  width: 16px !important;
}

.swiper-pagination-bullet-active {
  border-radius: 19px !important;
  background-color: black !important;
  border: 1px solid white !important;
  transition: 0.25s all ease-in-out;
}

#wallet-list::-webkit-scrollbar {
  display: none;
}

#fileInput,
#profilePic {
  display: none;
}

#wallet-list ul:nth-child(odd) {
  background-color: #323232;
}

/* hover:opacity-95 transition-all bg-white rounded-md py-2 sm:px-4 px-2 text-base */
.wallet-adapter-button {
  transition: all;
  background-color: transparent;
  border-radius: 0.375rem !important;
  padding-top: 0.5rem
    /* 8px */
    !important;
  padding-bottom: 0.5rem
    /* 8px */
    !important;
  padding-left: 0.5rem
    /* 8px */
    !important;
  padding-right: 0.5rem
    /* 8px */
    !important;
  font-size: 1rem !important;
  color: white !important;
  font-family: "Archia" !important;
  font-weight: normal !important;
}

.wallet-adapter-button-trigger {
  background-color: #ffffff !important;
  color: black !important;
}

@media (min-width: 640px) {
  .wallet-adapter-button {
    padding-left: 1rem
      /* 16px */
      !important;
    padding-right: 1rem
      /* 16px */
      !important;
  }
}

/********** OLD Style ***********/

/* .MuiFab-extended {
  height: auto;
  padding-right: 0 !important;
}

.MuiFab-label {
  font-weight: bold;
}

.MuiButton-label {
  font-size: 14px;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--main-text-color) !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--title-text-color) !important;
}

p {
  margin-top: 1rem;
}

.MuiButton-endIcon {
  background: #bb8221;
  color: white;
  border-radius: 0px 4px 4px 0px;
  margin-left: 10px;
  padding: 10px;
}

.wallet-adapter-button-trigger {
  font-family: "Inter" !important;
  background: rgb(22 37 87) !important;
  color: rgb(21 191 253) !important;
  width: 100% !important;
  border-radius: 9999px !important;
  font-size: 18px !important;
  max-width: fit-content;
}

.wallet-adapter-dropdown-list-active {
  opacity: 0;
  visibility: hidden;
}

} */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: black;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: white;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #ffffff;
  border-top-color: black;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-blur-bg {
  background: #00000030;
  box-shadow: 0 4px 30px #0000001a;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.custom-blur-bg::-webkit-scrollbar {
  display: none;
}